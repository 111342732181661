import { createRouter, createWebHistory } from 'vue-router'

import mustBeAuthenticated from '@/middleware/mustBeAuthenticated'
import redirectIfAuthenticated from '@/middleware/redirectIfAuthenticated'
import { ROUTE_KEYS } from './config/constant'
// import checkTracertStudy from "./middleware/checkTracertStudy";

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/components/Page/Main/Home/index'),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/login',
    name: 'Login Page',
    component: () => import('@/components/Page/Auth/login'),
    beforeEnter: redirectIfAuthenticated,
    meta: {
      layout: 'auth'
    }
  },
  {
    path: '/login/by_admin',
    name: 'Login By Admin',
    component: () => import('@/components/Page/Auth/loginByAdmin'),
    beforeEnter: redirectIfAuthenticated,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/logout-url',
    name: 'Logout',
    component: () => import('@/components/Page/Main/logout'),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('@/components/Page/Auth/ForgotPassword/forgot'),
    beforeEnter: redirectIfAuthenticated,
    meta: {
      layout: 'auth'
    }
  },
  {
    path: '/profile',
    name: 'Profile-Account',
    component: () => import('@/components/Page/Main/Profile/index'),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/beasiswa',
    name: 'Beasiswa',
    component: () => import('@/components/Page/Main/Beasiswa/index'),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: 'main'
    }
  },
   {
    path: '/beasiswa/:id',
    name: 'Beasiswa Detail',
    component: () => import('@/components/Page/Main/BeasiswaDetail/index'),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/stage-1',
    name: 'Stage-1',
    component: () => import('@/components/Page/Main/Stage1/index'),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/stage-1-payment',
    name: 'Stage-1-Payment',
    component: () => import('@/components/Page/Main/Stage1/payment'),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/stage-2',
    name: 'Stage-2',
    component: () => import('@/components/Page/Main/Stage2/index'),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: 'main'
    }
  },
  {
    path: ROUTE_KEYS.AUTHED_ROUTE.stage_3,
    name: ROUTE_KEYS.AUTHED_ROUTE.stage_3,
    component: () => import('@/components/Page/Main/Stage4/index'),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: 'main'
    }
  },
  {
    path: ROUTE_KEYS.AUTHED_ROUTE.stage_4_quiz,
    name: ROUTE_KEYS.AUTHED_ROUTE.stage_4_quiz,
    component: () => import('@/components/Page/Main/Stage4Quiz/index'),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: 'main'
    }
  },
  {
    path: ROUTE_KEYS.AUTHED_ROUTE.quiz,
    name: ROUTE_KEYS.AUTHED_ROUTE.quiz,
    component: () => import('@/components/Page/Main/Quiz/index.vue'),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: 'main'
    }
  },
  {
    path: `${ROUTE_KEYS.NON_MAHASISWA_ROUTE.review_external}/:token`,
    name: `${ROUTE_KEYS.NON_MAHASISWA_ROUTE.review_external}/:token`,
    component: () => import('@/components/Page/External/external-review'),

    meta: {
      layout: 'main'
    }
  },
  {
    path: ROUTE_KEYS.AUTHED_ROUTE.quiz_student,
    name: ROUTE_KEYS.AUTHED_ROUTE.quiz_student,
    component: () => import('@/components/Page/Main/QuizStudent/index.vue'),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: 'main'
    }
  },
  {
    path: ROUTE_KEYS.AUTHED_ROUTE.stage_4,
    name: ROUTE_KEYS.AUTHED_ROUTE.stage_4,
    component: () => import('@/components/Page/Main/Stage5/index'),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: 'main'
    }
  },
  {
    path: ROUTE_KEYS.AUTHED_ROUTE.stage_5,
    name: ROUTE_KEYS.AUTHED_ROUTE.stage_5,
    component: () => import('@/components/Page/Main/Stage6/index'),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: 'main'
    }
  },
  {
    path: ROUTE_KEYS.AUTHED_ROUTE.stage_6,
    name: ROUTE_KEYS.AUTHED_ROUTE.stage_6,
    component: () => import('@/components/Page/Main/Stage7/index'),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/components/Layouts/404.vue'),
    meta: { layout: 'notfound' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  }
})

export default router
